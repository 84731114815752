<template>
  <el-menu
    ref="menu"
    class="lz-menu"
    background-color="#497CF6"
    active-text-color="#FFF"
    :unique-opened="accordion"
    :default-active="activeName"
    :default-openeds="openedNames"
    @select="handleMenuItemSelect">
    <template v-for="menuData in menuList">
      <!-- 存在子菜单场景 -->
      <template v-if="menuData.children && menuData.children.length>0">
        <lz-sub-menu :key="`menu-${getMenuItemName(menuData)}`" class="lz-submenu level-1" :menu-data="menuData" :level="1"></lz-sub-menu>
      </template>

      <!-- 无子菜单场景 -->
      <template v-else>
        <el-menu-item
          :key="`menu-${getMenuItemName(menuData)}`"
          class="lz-menu-item level-1"
          :index="getMenuItemName(menuData)">
          <lz-icon class="lz-menu-icon" type="iconfont" :name="getMenuItemIcon(menuData)" :size="16"></lz-icon>
          <span slot="title" class="lz-menu-title">{{ getMenuItemTitle(menuData) }}</span>
        </el-menu-item>
      </template>
    </template>
  </el-menu>
</template>

<script>

import mixin from './mixin'
import LzSubMenu from './sub-menu.vue'

/**
 * 菜单视图
 */
export default {
  name: 'LzMenu',
  props: {
    // 数据源
    menuList: {
      type: Array,
      default: () => []
    },

    // 激活菜单的 name 值
    activeName: {
      type: String,
      default: ''
    },

    // 展开的 Submenu 的 name 集合
    openNames: {
      type: Array,
      default: () => []
    },

    // 是否开启手风琴模式
    accordion: Boolean,
  },
  mixins: [ mixin ],
  components: {
    LzSubMenu
  },
  data () {
    return {
      activedName: '',
      openedNames: []
    }
  },
  watch: {
    activeName (newVal) {
      // this.setOpenedNames();
    },
  },
  created () {
    this.setOpenedNames();
  },
  methods: {
    /**
     * 数据并集
     */
    getArrayUnion (arr1, arr2) {
      return Array.from(new Set([...arr1, ...arr2]));
    },
    
    /**
     * @description 根据需要查询的element，来获取所有父节点对象
     * @param { Array } data 数据源
     * @param { String } element 查询的数据
     * @return { Array } 返回所有父节点对象数组
     */
    arrFilterValue (data, element) {
      for(var i in data) {
        if(data[i].name === element) {
          return [data[i]];
        }
    
        if(data[i].children && data[i].children.length > 0) {
          let node = this.arrFilterValue(data[i].children, element);
          if (node) {
            return node.concat(data[i]);
          }
        }
      }
    },

    /**
     * 由当前菜单项获取展开的菜单项 name
     */
    getOpenedNamesByActiveName (name) {
      let parents = this.arrFilterValue(this.menuList, name);
      // 如果不为空，第一项为当前菜单项
      if (parents && parents.length>0) {
        parents.shift();
        return parents.map(item => item.name);
      } else {
        return [];
      }
    },

    /**
     * 设置当前展开的子菜单
     */
    setOpenedNames () {
      if (this.accordion) {
        this.openedNames = this.getOpenedNamesByActiveName(this.activeName);
      } else {
        this.openedNames = getArrayUnion(this.openedNames, this.getOpenedNamesByActiveName(this.activeName));
      }
    },

    /**
     * 菜单项（MenuItem）选择事件
     * @param { String } name 菜单项的唯一标识
     */
    handleMenuItemSelect (name) {
      this.$emit('on-select', name);
    }
  }
}
</script>

<style>
  .lz-menu.el-menu {
    border-right: none;
    color: rgba(215, 227, 255, 0.8);
  }

  .lz-menu .lz-menu-item,
  .lz-menu .lz-menu-icon {
    color: inherit;
  }

  .lz-menu .lz-menu-icon {
    margin-right: 8px;
  }

  .lz-menu .lz-menu-title {
    user-select: none;
  }

  .lz-menu .el-submenu__title,
  .lz-menu .el-submenu__title .el-submenu__icon-arrow {
    color: inherit;
  }


  /* 存在子菜单场景 */
  .lz-menu .lz-submenu .el-submenu__title {
    height: 52px;
    line-height: 52px;
  }

  .lz-menu .lz-submenu .el-submenu__icon-arrow {
    margin-top: -5px;
  }

  .lz-menu .lz-submenu .lz-menu-item {
    height: 42px;
    line-height: 42px;
    min-width: unset;
  }

  .lz-menu .lz-submenu .lz-menu-item.level-2 {
    padding-left: 36px !important;
  }

  .lz-menu .lz-submenu.level-2 .el-submenu__title {
    padding-left: 44px !important;
  }
  
  /* 无子菜单场景 */
  .lz-menu > .lz-menu-item {
    height: 52px;
    line-height: 52px;
    padding: 0 16px 0 24px !important;
  }

  .lz-menu .lz-menu-item:hover,
  .lz-menu .lz-menu-item:focus,
  .lz-menu .el-submenu__title:hover {
    background-color: #497CF6 !important;
  }

  .lz-menu .lz-menu-item:hover,
  .lz-menu .el-submenu__title:hover,
  .lz-menu .lz-menu-item:hover .lz-menu-icon {
    color: #FFF !important;
  }


  /* 菜单 active 状态 */
  .lz-menu .lz-submenu.is-active > .el-submenu__title {
    color: #FFF;
    background-color: rgba(255, 255, 255, 0.12) !important;
  }

  .lz-menu > .lz-menu-item.is-active {
    background-color: rgba(255, 255, 255, 0.12) !important;
  }

  .lz-menu .lz-submenu.is-active > .el-submenu__title::before,
  .lz-menu > .lz-menu-item.is-active::before {
    content: '';
    display: block;
    width: 3px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #FFF;
  }
</style>