var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-submenu",
    { attrs: { index: _vm.menuData.name } },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.level === 1
            ? _c("lz-icon", {
                staticClass: "lz-menu-icon",
                attrs: {
                  type: "iconfont",
                  name: _vm.getMenuItemIcon(_vm.menuData),
                  size: 16
                }
              })
            : _vm._e(),
          _c("span", { staticClass: "lz-menu-title" }, [
            _vm._v(_vm._s(_vm.getMenuItemTitle(_vm.menuData)))
          ])
        ],
        1
      ),
      _vm._l(_vm.menuData.children, function(childMenuData) {
        return [
          childMenuData.children &&
          childMenuData.children.length > 0 &&
          _vm.level < 2
            ? [
                _c("lz-sub-menu", {
                  key: `menu-${_vm.getMenuItemName(childMenuData)}`,
                  class: ["lz-submenu", `level-${_vm.level + 1}`],
                  attrs: { "menu-data": childMenuData, level: _vm.level + 1 }
                })
              ]
            : [
                _c(
                  "el-menu-item",
                  {
                    key: `menu-${_vm.getMenuItemName(childMenuData)}`,
                    class: ["lz-menu-item", `level-${_vm.level + 1}`],
                    attrs: { index: _vm.getMenuItemName(childMenuData) }
                  },
                  [
                    _vm.level === 1
                      ? _c("lz-icon", {
                          staticClass: "lz-menu-icon",
                          attrs: {
                            type: "iconfont",
                            name: _vm.getMenuItemIcon(childMenuData),
                            size: 16
                          }
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "lz-menu-title" }, [
                      _vm._v(_vm._s(_vm.getMenuItemTitle(childMenuData)))
                    ])
                  ],
                  1
                )
              ]
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }