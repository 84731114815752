<template>
  <el-drawer
    :visible="visible"
    :size="width"
    append-to-body
    :wrapper-closable="maskClosable"
    :close-on-press-escape="maskClosable"
    :before-close="beforeClose"
    :custom-class="'lz-drawer lz-drawer-'+name"
    @close="handleDrawerClose">
    <template slot="title">
      <slot name="header" v-if="customHeader"></slot>
      <div v-else class="lz-drawer__header">
        <span>{{ title }}</span>
      </div>
    </template>

    <!-- 内容视图 -->
    <slot></slot>
  </el-drawer>
</template>

<script>
/**
 * 封装自定义 Drawer 组件
 */
export default {
  name: 'LzDrawer',
  model: {
    // 自定义用于 v-model 的 prop 和事件
    prop: 'visible',
    event: 'change'
  },
  props: {
    // 抽屉是否显示
    visible: {
      type: Boolean,
      default: false
    },

    // drawer 标识
    name: {
      type: String,
      default: ''
    },

    // 抽屉标题
    title: {
      type: String,
      default: ''
    },

    // 抽屉宽度
    width: {
      type: [Number, String],
      default: 460,
    },

    // 是否允许点击遮罩层关闭
    maskClosable: {
      type: Boolean,
      default: true
    },

    // 是否显示 loading 视图
    loadingEnable: Boolean,
    loading: Boolean,

    // 关闭前的回调，会暂停 Drawer 的关闭
    beforeClose: {
      type: Function
    }
  },
  data () {
    return {
      // 是否自定义 header
      customHeader: false,

      // loading 对象
      loadingIns: null
    }
  },
  watch: {
    loading (newVal) {
      if (this.loadingEnable) {
        this.setLoading(newVal);
      }
    },
  },
  mounted () {
    this.customHeader = this.$slots.header !== undefined;
  },
  methods: {
    /**
     * 设置 loading
     * @param { Boolean } loading 是否是 loading 状态
     */
    setLoading (loading) {
      if (loading) {
        this.loadingIns = this.$loading({
          lock: true,
          target: this.name ? `.lz-drawer-${this.name}` : '.lz-drawer'
        });
      } else if (!loading && this.loadingIns) {
        this.loadingIns.close();
        this.loadingIns = null;
      }
    },

    // 关闭事件
    handleDrawerClose () {
      this.$emit('close');
      // 修改 v-model prop 值
      this.$emit('change', false);
    }
  }
}
</script>

<style lang="scss">
  @import "@/style/const";
  @import "@/style/mixins";

  .v-modal {
    background-color: #000512 !important;
  }

  .lz-drawer {
    $_text-color: $text-color;
    $_border-color: $border-color;
    $_dividing-line-color: $dividing-line-color;
    $_theme-color: $theme-color;
    box-sizing: border-box;
    box-shadow: -12px 0 48px 16px rgba(0, 0, 0, 0.03), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -6px 0 16px -8px rgba(0, 0, 0, 0.08) !important;
    
    .el-drawer__header {
      color: $_text-color;
      padding: 0 12px 0 16px;
      margin-bottom: 0;
      border-bottom: 1px solid $_border-color;
    }

    .lz-drawer__header {
      font-size: 14px;
      font-weight: bold;
      height: 48px;
      line-height: 48px;

      @include no-wrap-single;
    }

    .lz-drawer__box {
      height: 100%;
    }

    .lz-drawer__body {
      width: 100%;
      height: calc(100% - 53px);
      padding: 16px;
      overflow: auto;
    }

    .lz-drawer__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
      z-index: 5;
    }

    .lz-drawer__footer.lz-drawer__footer__line {
      border-top: 1px solid $_dividing-line-color;
    }

    .lz-drawer__footer__button {
      min-width: 65px;
    }

    .lz-drawer__footer__button.confirm {
      color: #fff;
      background-color: $_theme-color;
      border-color: $_theme-color;
    }

    .lz-drawer__footer__button.cancel {
      border: 1px solid $_border-color;
    }

    .lz-drawer__footer button + button {
      margin-left: 8px;
    }
  }
</style>
