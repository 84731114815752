import Main from '@/view/public/main'

// 首页
export default [
  {
    path: '/homeManager',
    name: 'hrms_home',
    redirect: 'home',
    component: Main,
    meta: {
      title: '首页'
    },
    icon: 'hrms_home',
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: '首页',
          menuName: 'hrms_home'
        },
        component: () => import('./home.vue')
      }
    ]
  }
]