import cookies from 'js-cookie'

// sso access token key
let cTokenName = 'x-access-titc-s-token';


// Cookie 缓存 domain
let cacheDomain = process.env.NODE_ENV==='development' ? window.location.hostname : window.location.hostname.split('.').slice(-2).join('.');

/**
 * 获取 Cookie 中的 token
 * @returns { String|Undefined } 缓存值
 */
export function getTokenFromCookie () {
  return cookies.get(cTokenName);
}
