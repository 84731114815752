import systemHelper from '@/libs/system'
import {getTokenFromCookie} from '@/libs/oauth'
import cacheHelper from './cache'
import serviceApi from './api'
import { setStorage} from "@/utils/storage";


// 云平台 project code
const XCLOUD_PROJECT_CODE = 'xcloud';

/** 公共基础数据处理 **/
/**
 * 获取菜单、页面及按钮权限信息
 */
function getMenuInfo (route) {

  const token = getTokenFromCookie()
  if(token) {
    setStorage('hrms_to', token)
  }
  
  return new Promise((resolve, reject) => {

    const { query } = route
    const projectCode = query?.pCode || 'hrms'
    if(projectCode) {
      setStorage('hrms_pro_code', projectCode)
    }

    serviceApi.menuPageInfo({ projectCode }).then(res => {
      if (res.code == 200) {
        _getUserInfo(res.data, resolve, reject);
      } else {
        reject(new Error(res.message));
      }
    }).catch(error => {
      reject(error);
      console.error('Get menu info error: ', error.message);
    });
  });
}


function _getUserInfo (menuData, resolve, reject) {
  serviceApi.userInfo().then(res => {
    if (res.code==200 && res.data) {
      let urlLocal = ['/401', '/404', '/500', '/redirect'];
      let urls = urlLocal.concat(menuData.urls || []);
      cacheHelper.cachePageUrls(urls);
      cacheHelper.cacheMenuList(menuData.menuTrees);
      cacheHelper.cachePageButtons(menuData.pages);

      cacheHelper.cacheUserInfo(res.data);
      resolve();
    } else {
      reject(new Error(res.message));
    }
  }).catch(error => {
    reject(error);
    console.error('Get user info error: ', error.message);
  });
}


/**
 * 退出登录
 */
function logout () {
  return new Promise((resolve, reject) => {
    serviceApi.logout().then(res => {
      if (res.code == 200) {
        resolve();
      } else {
        reject(new Error(res.message));
      }
    }).catch(error => {
      reject(error);
      console.error('Logout error: ', error.message);
    });
  });
}


/**
 * 修改密码
 * @note 用于修改登录人密码
 * @param { Object } data 密码数据
 */
function changePassword (data) {
  return new Promise((resolve, reject) => {
    serviceApi.changePassword(data).then(res => {
      if (res.code == 200) {
        resolve();
      } else {
        reject(new Error(res.message));
      }
    }).catch(error => {
      reject(error);
      console.error('Change password error: ', error.message);
    });
  });
}


/**
 * 获取解析后的菜单列表
 * @returns { Array } 解析后的数据
 */
function getParsedMenuList () {
  let rs = cacheHelper.getLocalCachedMenuList();
  return systemHelper.parseMenuList(rs || []);
}





/**
 * 基础数据处理
 */
export default {
  XCLOUD_PROJECT_CODE,

  getMenuInfo,
  changePassword,
  logout,
  getParsedMenuList
}
