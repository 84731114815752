import cacheHelper from '@/view/common/cache'

export default {
  state: {
    // 用户信息
    userInfo: null,

    // 权限 page url
    pageUrls: null,

    // 权限按钮数据
    pageButton: null
  },
  getters: {
    /**
     * 获取缓存的用户信息
     */
    userInfo: state => {
      if (state.userInfo && Object.keys(state.userInfo).length) {
        return state.userInfo;
      }
      let cachedData = cacheHelper.getLocalCachedUserInfo();
      state.userInfo = cachedData || {};
      return state.userInfo;
    },

    /**
     * 缓存的权限按钮数据
     */
    pageButton: state => {
      if (state.pageButton) {
        return state.pageButton;
      }
      let cachedData = cacheHelper.getLocalCachedPageButtons();
      state.pageButton = cachedData;
      return cachedData;
    },

    /**
     * 是否存在指定的权限按钮
     * @param { String } pageAlias 页面别名（菜单别名） 
     * @param { String } buttonAlias 按钮别名 
     * @returns { Boolean } true：具有权限按钮，false：无权限按钮
     */
    pageButtonPermission: (state, getters) => (pageAlias, buttonAlias) => {
      let pageButtons = getters.pageButton || [];
      let pageItem = pageButtons.find(item => item.resourceAlias === pageAlias);
      let buttonList = pageItem ? pageItem.children : [];
      return buttonList.findIndex(item => item.resourceAlias === buttonAlias) !== -1;
    },

    /**
     * 获取用户所属组织 code
     * @note 优先获取“blocCode”，如果不存在则获取“tenantCode” 
     */
    userOrgCode: (state, getters) => {
      const userInfo = getters.userInfo;
      return userInfo.blocCode || userInfo.tenantCode || '';
    },

    /**
     * 获取用户所属组织名称
     * @note 优先获取“集团名称”，如果不存在则获取“租户名称”
     */
    userOrgName: (state, getters) => {
      const userInfo = getters.userInfo;
      return userInfo.blocName || userInfo.tenantName || '';
    },

    /**
     * 是否是“集团租户”用户
     * @note 此类型下成员需关联酒店
     */
    isTenantTypeBlocUser: (state, getters) => {
      const userInfo = getters.userInfo;
      return userInfo.tenantType==undefined || userInfo.tenantType==1;
    },

    /**
     * 是否是“酒管组租户”用户
     */
    isTenantTypeBlocSetUser: (state, getters) => {
      const userInfo = getters.userInfo;
      return userInfo.tenantType == 3;
    }
  },
  mutations: {
    /**
     * 设置用户信息
     * @param { Object } data 用户信息
     */
    setUserInfo(state, data) {
      state.userInfo = data || {};
    },

    /**
     * 缓存权限 page url
     * @param { Array } data page urls 数据
     */
    setPageUrls (state, data) {
      state.pageUrls = data;
    },

    /**
     * 设置权限按钮数据
     * @param { Array } data 权限按钮数据
     */
    setPageButton(state, data) {
      state.pageButton = data;
    }
  },
  actions: {}
}
