<template>
  <i :class="[type, name]" :style="iconStyle"></i>
</template>

<script>
  /**
   * Icon 组件
   */
  export default {
    name: 'LzIcon',
    props: {
      // icon 基类
      type: {
        type: String,
        required: true,
      },

      // icon 名称
      name: {
        type: String,
        default: ''
      },

      // icon 字体颜色
      color: {
        type: String,
        default: ''
      },

      // icon 字体大小
      size: {
        type: Number,
        default: 12
      }
    },
    computed: {
      iconStyle () {
        return {
          fontSize: `${this.size}px`,
          color: this.color
        }
      }
    }
  }
</script>
