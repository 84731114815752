/**
 * Vue filter
 */

import Vue from 'vue'


/**
 * “缺省字符” 过滤器
 * @param { String } value 原始字符串
 * @param { String } placeholder 占位字符
 */
Vue.filter('default_chars', function (value, placeholder='--') {
  if (value) {
    return value;
  } else {
    return placeholder;
  }
});
