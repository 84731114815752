var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-aside",
    { staticClass: "lz-side", attrs: { width: _vm.width } },
    [
      _c("lz-menu", {
        attrs: {
          "menu-list": _vm.menuList,
          "active-name": _vm.activeName,
          accordion: ""
        },
        on: {
          "on-select": name => {
            _vm.$emit("on-select", name)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }