import Main from '@/view/public/main'

// 考勤管理
export default [
  {
    path: '/attendance',
    name: 'hrms_attendance',
    redirect: 'hotel_schedule',
    component: Main,
    meta: {
      title: '考勤管理'
    },
    icon: 'hrms_attendance',
    children: [
      {
        path: 'schedule',
        name: 'hotel_schedule',
        meta: {
          title: '门店考勤'
        },
        component: () => import('./schedule/index.vue')
      },
      {
        path: 'group',
        name: 'group_attendance',
        meta: {
          title: '集团考勤'
        },
        component: () => import('./group/index.vue')
      }
    ]
  }
]
