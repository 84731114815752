import LzIcon from '_c/icon'

export default {
  components: {
    LzIcon
  },
  methods: {
    /**
     * 菜单项 icon
     * @param { Object } item 菜单项数据源
     */
    getMenuItemIcon (item) {
      return item.icon;
    },

    /**
     * 菜单项 title
     * @param { Object } item 菜单项数据源
     */
    getMenuItemTitle (item) {
      return this.$config.useI18n ? this.$t(item.name) : ((item.meta && item.meta.title) || item.name);
    },

    /**
     * 菜单项名称
     * @param { Object } item 菜单项数据源
     */
    getMenuItemName (item) {
      return item.name;
    },

    /**
     * 菜单项 url
     * @param { Object } item 菜单项数据源
     */
    getMenuItemUrl (item) {
      return (item.meta && item.meta.url) || '';
    }
  }
}
