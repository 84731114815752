import cookies from 'js-cookie'
import cacheHelper from '@/view/common/cache'

var ACCOUNT_IDENTITY_KEY = 'x-access-titc-fe-account';


/** 身份标识 **/
/**
 * 获取 SSO 缓存的身份标识数据
 * @returns { String|Undefined } 缓存值
 */
function _getLocalCachedAccountIdentity () {
  return cookies.get(ACCOUNT_IDENTITY_KEY);
}

/**
 * 由本地缓存的 user info 获取 identity 数据
 * @returns { String|Undefined } identity 数据
 */
function _getLocalIdentityData () {
  let identity;
  let cachedData = cacheHelper.getLocalCachedUserInfo();
  if (cachedData && cachedData.tenantCode && cachedData.userId) {
    identity = cachedData.tenantCode + (cachedData.blocCode||'') + cachedData.userId;
  }
  return identity;
}


/**
 * 登录账号变更检查
 * @note 2.0 逻辑
 * - SSO 会缓存身份标识数据
 * - 检查缓存数据存在并且相同判断是否已切换账号登录
 * @returns { Boolean } true/false
 */
function checkAccountChange () {
  let sso_identity = _getLocalCachedAccountIdentity();
  let identity = _getLocalIdentityData();
  if (sso_identity && identity && sso_identity!=identity) {
    cacheHelper.clearLocalCache();
    return true;
  }
  return false;
}


/**
 * 账号检查逻辑
 */
export default {
  checkAccountChange
}
