import Main from '@/view/public/main'

// 设置页
export default [
  {
    path: '/settingManager',
    name: 'hrms_setting',
    redirect: 'setting',
    component: Main,
    meta: {
      title: '设置'
    },
    icon: 'hrms_setting',
    children: [
      {
        path: '/setting',
        name: 'setting',
        meta: {
          title: '设置',
          menuName: 'hrms_setting'
        },
        component: () => import('./setting.vue')
      }
    ]
  }
]