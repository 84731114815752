var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-menu",
    {
      ref: "menu",
      staticClass: "lz-menu",
      attrs: {
        "background-color": "#497CF6",
        "active-text-color": "#FFF",
        "unique-opened": _vm.accordion,
        "default-active": _vm.activeName,
        "default-openeds": _vm.openedNames
      },
      on: { select: _vm.handleMenuItemSelect }
    },
    [
      _vm._l(_vm.menuList, function(menuData) {
        return [
          menuData.children && menuData.children.length > 0
            ? [
                _c("lz-sub-menu", {
                  key: `menu-${_vm.getMenuItemName(menuData)}`,
                  staticClass: "lz-submenu level-1",
                  attrs: { "menu-data": menuData, level: 1 }
                })
              ]
            : [
                _c(
                  "el-menu-item",
                  {
                    key: `menu-${_vm.getMenuItemName(menuData)}`,
                    staticClass: "lz-menu-item level-1",
                    attrs: { index: _vm.getMenuItemName(menuData) }
                  },
                  [
                    _c("lz-icon", {
                      staticClass: "lz-menu-icon",
                      attrs: {
                        type: "iconfont",
                        name: _vm.getMenuItemIcon(menuData),
                        size: 16
                      }
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "lz-menu-title",
                        attrs: { slot: "title" },
                        slot: "title"
                      },
                      [_vm._v(_vm._s(_vm.getMenuItemTitle(menuData)))]
                    )
                  ],
                  1
                )
              ]
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }