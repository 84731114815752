<template>
  <div class="lz-page-panel">
    <template v-if="showHeader">
      <slot v-if="customHeader" name="header"></slot>
      <div v-else class="lz-panel__header">
        <label class="lz-panel__title">{{ title }}</label>
      </div>
    </template>
    
    <div class="lz-panel__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/**
 * 主页面容器组件
 */
export default {
  name: 'LzPagePanel',
  props: {
    title: String,

    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      // 是否自定义 header 视图
      customHeader: false
    }
  },
  mounted () {
    this.customHeader = this.$slots.header !== undefined;
  }
}
</script>

<style>
  .lz-page-panel {
    height: 100%;
    display: flex;
    flex-flow: column;
    background-color: #fff;
    position: relative;
    overflow: hidden;
  }

  .lz-page-panel .lz-panel__header {
    position: relative;
    height: 20px;
    line-height: 20px;
    padding: 0 0 0 10px;
    margin: 20px;
  }

  .lz-page-panel .lz-panel__title {
    color: #414655;
    font-size: 18px;
    font-weight: bold;
  }

  .lz-page-panel .lz-panel__header::after {
    content: '';
    width: 3px;
    height: 14px;
    background-color: #497CF6;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 1.5px;
    transform: translateY(-50%);
  }

  .lz-page-panel .lz-panel__body {
    flex: 1;
    overflow: auto;
    padding: 0 20px;
  }
</style>
