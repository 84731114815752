var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "vertical-center",
      attrs: {
        visible: _vm.visible,
        width: _vm.width,
        top: "0",
        "append-to-body": "",
        "close-on-click-modal": _vm.maskClosable,
        "close-on-press-escape": _vm.maskClosable,
        "custom-class": "lz-modal lz-modal-" + _vm.name
      },
      on: { close: _vm.handleModalClose }
    },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.customHeader
            ? _vm._t("header")
            : _c("div", { staticClass: "lz-modal__header" }, [
                _c("span", [_vm._v(_vm._s(_vm.title))])
              ])
        ],
        2
      ),
      _vm._t("body"),
      !_vm.footerHide
        ? _c(
            "template",
            { slot: "footer" },
            [
              _vm.customFooter
                ? _vm._t("footer")
                : _c(
                    "div",
                    { staticClass: "lz-modal__footer" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "lz-modal__footer__button confirm",
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleModalConfirm()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.confirmButtonText))]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "lz-modal__footer__button cancel",
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleModalCancel()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.cancelButtonText))]
                      )
                    ],
                    1
                  )
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }