var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["lz-tag", { "lz-tag__dot": _vm.type === "dot" }],
      style: _vm.tagStyle
    },
    [
      _vm.type === "dot"
        ? _c("span", {
            staticClass: "lz-tag__dot__inner",
            style: _vm.tagInnerStyle
          })
        : _vm._e(),
      _c("span", { staticClass: "lz-tag__text" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }