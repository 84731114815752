import config from "@/config"

/**
 * 构造缓存键名
 * @param { String } key 键名
 * @param { Boolean } isolate 是否添加前缀进行数据隔离
 * @returns 缓存键名
 */
function _cacheKey (key, isolate) {
  return (isolate ? config.projectName+'_' : '') + key;
}

/**
 * Storage 处理模块
 * @module libs/cache
 */
export default (type, isolate=true) => {
  var storageInstance;
  if (type === 'local') {
    storageInstance = window.localStorage;
  } else {
    storageInstance = window.sessionStorage;
  }
  var storage = {
    /**
     * 获取 Storage 存储的数据
     * @note 只能存储 String 类型值，需对 value 作 JSON 反序列化
     * @param { String } key Storage key
     * @return { String|Array|Object|Error|Null } 从 Storage 中获取的值；如果值不存在，则返回 null
     */
    get (key) {
      if (!key || typeof key !== 'string') {
        return new Error('Get storage item error, key invalid');
      }
      var data = storageInstance.getItem(_cacheKey(key, isolate));
      return JSON.parse(data);
    },

    /**
     * Storage 存储数据
     * @note 只能存储 String 类型值，需对 value 作 JSON 序列化
     * @param { String } key Storage key
     * @param { String|Array|Object|Boolean|Number } value Storage value
     */
    set (key, value) {
      if (key && typeof key=='string' && value!=='' && value!=undefined && value!=null) {
        var serializedValue = JSON.stringify(value);
        storageInstance.setItem(_cacheKey(key, isolate), serializedValue);
      }
    },

    /**
     * 删除 Storage 存储数据
     * @param { String } key Storage key
     */
    remove (key) {
      if (key) {
        storageInstance.removeItem(_cacheKey(key, isolate));
      }
    },

    /**
     * 清除 Storage 中所有值
     */
    clear () {
      storageInstance.clear();
    }
  }
  return storage;
}