import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
import systemHelper from '@/libs/system'
import basicHelper from '@/view/common/basic'
import accountHelper from '@/view/common/accountCheck'
import menuHelper from '@/view/public/main/components/side-menu/helper'
import Cookies from '@/libs/cookie';
import { getSession, setSession } from '@/libs/stroage'

Vue.use(Router)

const router = new Router({
  routes,
  mode: 'history',
  base: '/'
})

// @note 中断当前导航并 redirect 到其他路由，控制台会输出：Uncaught (in promise) Error: Redirected when going from "X" to "Y" via a navigation guard. 这里是官方回复:
// https://github.com/vuejs/vue-router/issues/3260

router.beforeEach((to, from, next) => {

  let sToken = getSession('s-token')
  const cToken = Cookies.get('x-access-titc-s-token')
  if(cToken && cToken.length > 0 && !sToken) {
    setSession('s-token', cToken)
    sToken = cToken
  }
  if (!(cToken && sToken && cToken === sToken)) {
    systemHelper.loginByOAuth('N');
    next();
    return false;
  }


  // const token = Cookies.get('x-access-titc-s-token');
  // if (!token) {
  //   systemHelper.loginByOAuth();
  //   next();
  //   return false;
  // }
  //处理控制非法域名
  if (['error_404', 'error_500'].includes(to.name) ) {
    next()
    return false
  }

  let code = systemHelper.testPageUrlPower(to.path)
  if (to.name === '_span' && code === 1) {
    // 处理历史缓存数据
    code = 3
  }
  if (code === 1) {
    next()
  } else if (code === 2) {
    // 如果路由为“/”，导航至第一个菜单项；如果无菜单，导航至 401
    let toRoute = { ...to },
      replace = false;
    if (to.name === '_span') {
      let menuList = basicHelper.getParsedMenuList()
      let leafMenu = menuHelper.checkLeafMenuData(menuList, routes)

      console.log('leafMenu================', leafMenu)

      if (leafMenu) {
        toRoute.name = leafMenu.name
      } else {
        toRoute.name = 'error_401'
        replace = true
      }
    } else {
      toRoute.name = 'error_401'
    }
    next({
      name: toRoute.name,
      query: toRoute.query,
      replace
    })
    return false

  } else {
    // 没有获取菜单和路由权限的情况
    basicHelper.getMenuInfo(to).then(data => {
      // 指定路由名称后，会重走 beforeEach 回调，再次判断是否具有页面权限
      let toData = {
        name: to.name,
        query: to.query
      }
      next(toData)
    }).catch(error => {})
    return false
  }

  // // 检查账号变更
  // if (to.name !== 'redirect' && accountHelper.checkAccountChange()) {
  //   next({
  //     path: '/redirect',
  //     replace: true
  //   })
  // }
  next()
})

router.afterEach(to => {
  window.scrollTo(0, 0)
})

export default router
