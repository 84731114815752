import config from '@/config'
import HttpRequest from '@/libs/http'

// 封装 axios http requst
const http = new HttpRequest();

class Ajax {
  constructor (domain) {
    this.domain = domain || config.serverConfig.serviceURL;
  }

  /**
   * json 转 url 参数
   * @param { Object } param 参数对象
   * @returns 拼接后的参数值
   */
  _parseParam (param) {
    return Object.keys(param).map(function (key) {
      return encodeURIComponent(key) + "=" + encodeURIComponent(param[key]);
    }).join("&");
  }

  /**
   * GET 请求
   * @param { String } url 接口 url
   * @returns Promise 对象
   */
  get (url) {
    const that = this;
    return async function (data = {}, extraConfig = { autoCancel: false }) {
      let res;
      try {
        res = await http.request({
          baseURL: that.domain,
          url,
          params: data,
          method: 'get'
        }, extraConfig);
        return Promise.resolve(res.data);
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }

  /**
   * POST 请求
   * @param { String } url 接口 url
   * @returns Promise 对象
   */
  post (url) {
    const that = this;
    return async function (data = {}) {
      let res;
      try {
        res = await http.request({
          baseURL: that.domain,
          url,
          data,
          method: 'post'
        });
        return Promise.resolve(res.data);
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }

  /**
   * POST 请求
   * @note 数据以 FormData 形式传递
   * @param { String } url 接口 url
   * @returns Promise 对象
   */
  formPost (url) {
    const that = this;
    return async function (data = {}) {
      let res;
      try {
        res = await http.request({
          baseURL: that.domain,
          url,
          data: that._parseParam(data),
          method: 'post'
        });
        return Promise.resolve(res.data);
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }

  /**
   * PUST 请求
   * @param { String } url 接口 url
   * @returns Promise 对象
   */
  put (url) {
    const that = this;
    return async function (data = {}) {
      let res;
      try {
        res = await http.request({
          baseURL: that.domain,
          url,
          data,
          method: 'put'
        });
        return Promise.resolve(res.data);
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }

  /**
   * 上传请求
   * @param { String } url 接口 url
   * @returns Promise 对象
   */
  upload (url) {
    const that = this;
    return async function (data = {}) {
      let res;
      try {
        res = await http.request({
          baseURL: that.domain,
          url,
          data,
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        return Promise.resolve(res.data);
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }

  /**
   * 下载请求
   * @param { String } url 接口 url
   * @returns Promise 对象
   */
  download (url, method="get") {
    const that = this;
    return async function (data = {}) {
      let res;
      try {
        let config = {
          baseURL: that.domain,
          url,
          method,
          responseType: 'blob'
        }
        if (method.toLowerCase() === 'get') {
          config.params = data;
        } else if (method.toLowerCase() === 'post') {
          config.data = data;
        }
        res = await http.request(config);
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }
}


export default Ajax
