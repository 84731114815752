
// 动态查找项目路由
let routerList = [];
const r = require.context('../view', true, /\.routes\.js/);
r.keys().forEach(key => {
  routerList = routerList.concat(r(key).default)
});

export default [
  {
    path: '/',
    name: '_span',
    redirect: '/homeManager',
    meta: {
      hideInMenu: true,
      notCache: true
    },
    children: []
  },
  {
    path: '/401',
    name: 'error_401',
    meta: {
      hideInMenu: true
    },
    component: () =>
      import('@/view/public/error-page/401.vue')
  },
  {
    path: '*',
    name: 'error_404',
    meta: {
      hideInMenu: true
    },
    component: () =>
      import('@/view/public/error-page/404.vue')
  },
  {
    path: '/500',
    name: 'error_500',
    meta: {
      hideInMenu: true
    },
    component: () =>
      import('@/view/public/error-page/500.vue')
  },
  {
    path: '/redirect',
    name: 'redirect',
    meta: {
      hideInMenu: true
    },
    component: () =>
      import('@/view/public/error-page/redirect.vue')
  },
  ...routerList,
]
