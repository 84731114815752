<template>
  <el-aside class="lz-side" :width="width">
    <lz-menu
      :menu-list="menuList"
      :active-name="activeName"
      accordion
      @on-select="(name) => { $emit('on-select', name) }">
    </lz-menu>
  </el-aside>
</template>

<script>

import LzMenu from './menu.vue'

/**
 * 侧边栏+菜单视图组件
 */
export default {
  name: 'LzSideMenu',
  props: {
    width: {
      type: String,
      default: '180px'
    },

    // 数据源
    menuList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    LzMenu
  },
  data () {
    return {
      // 激活菜单的 name（支持本地路由动态设置）
      activeName: ''
    }
  },
  watch: {
    $route: function (newVal) {
      // lz-side-menu 内子菜单切换触发路由变更
      this.activeName = newVal.meta.menuName || newVal.name;
    }
  },
  created () {
    // lz-side-menu 组件间切换触发路由变更
    this.activeName = this.$route.meta.menuName || this.$route.name;
  }
}
</script>

<style scoped>
  .lz-side {
    padding: 6px 0;
  }
</style>
