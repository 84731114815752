import config from '@/config'
import Ajax from '@/libs/ajax.js'

const ajax = new Ajax();


// 考勤异常标识枚举
const ATTENDANCE_ABNORMAL_MARK_DEFAULT = [
  { id: '10', title: '迟到', color: 'rgba(147, 112, 219, 0.2)' },
  { id: '01', title: '早退', color: 'rgba(248, 173, 22, 0.2)' },
  { id: '11', title: '迟到+早退', color: 'rgba(255, 77, 79, 0.2)' }
]

/**
 * 考勤 - 状态管理
 */
export default {
  namespaced: true,
  state: {
    // 职位枚举
    jobEnum: [],
    // 班次类型枚举
    scheduleTypeEnum: [],
    // 考勤状态枚举
    attendanceStateEnum: [],
    // 考勤异常标识枚举
    abnormalMarkEnum: ATTENDANCE_ABNORMAL_MARK_DEFAULT
  },
  getters: {
    /**
     * 获取“班次”名称
     * @param { Number } type 类型值
     * @returns { String } 班次名称
     */
    scheduleTypeName: (state) => (type) => {
      let item = state.scheduleTypeEnum.find(item => item.workShiftType == type);
      if (item) {
        return item.workShiftName;
      }
      return '';
    },

    /**
     * 获取“考勤状态”名称
     * @param { Number } state 状态值
     * @returns { String } 状态名称
     */
    attendanceStateName: (state) => (state) => {
      let item = state.attendanceStateEnum.find(item => item.attendanceStatus == state);
      if (item) {
        return item.attendanceName;
      }
      return '';
    },

    /**
     * 考勤异常标识数据
     * @param { Number } lateState 迟到状态
     * @param { Number } leaveEarlyState 早退状态
     * @returns { Object } 标识数据
     */
    attendanceAbnormalMark: (state) => (lateState, leaveEarlyState) => {
      let markId = lateState + '' + leaveEarlyState;
      return state.abnormalMarkEnum.find(item => item.id == markId);
    },

    /**
     * “考勤状态”是否异常
     * @param { Number } lateState 迟到状态
     * @param { Number } leaveEarlyState 早退状态
     * @returns { Boolean } 是否异常
     */
    isAttendanceAbnormal: (state) => (lateState, leaveEarlyState) => {
      return lateState==1 || leaveEarlyState==1;
    }
  },
  mutations: {
    /**
     * 缓存“职位”枚举数据
     * @param { Array } data 枚举数据
     */
    setJobEnum (state, data) {
      state.jobEnum = data || [];
    },

    /**
     * 缓存“班次”枚举数据
     * @param { Array } data 枚举数据
     */
    setScheduleTypeEnum (state, data) {
      state.scheduleTypeEnum = data || [];
    },

    /**
     * 缓存“考勤状态”枚举数据
     * @param { Array } data 枚举数据
     */
    setAttendanceStateEnum (state, data) {
      state.attendanceStateEnum = data || [];
    }
  },
  actions: {
    /**
     * 获取“职位”枚举
     */
    getJobSelectList (context) {
      return new Promise((resolve, reject) => {
        if (context.state.jobEnum && context.state.jobEnum.length) {
          resolve(context.state.jobEnum);
        } else {
          ajax.post(config.apiConf.serviceApi + 'workshift/listJob')().then(res => {
            if (res.code==200 && res.data) {
              context.commit('setJobEnum', res.data);
              resolve(res.data);
            } else {
              reject(new Error(res.message));
            }
          }).catch(error => {
            reject(error);
            console.error('Get job select list error: ', error.message);
          });
        }
      });
    },

    /**
     * 获取“班次”枚举
     */
    getWorkShiftSelectList (context) {
      return new Promise((resolve, reject) => {
        if (context.state.scheduleTypeEnum && context.state.scheduleTypeEnum.length) {
          resolve(context.state.scheduleTypeEnum);
        } else {
          ajax.post(config.apiConf.serviceApi + 'workshift/listWorkShiftType')().then(res => {
            if (res.code==200 && res.data) {
              context.commit('setScheduleTypeEnum', res.data);
              resolve(res.data);
            } else {
              reject(new Error(res.message));
            }
          }).catch(error => {
            reject(error);
            console.error('Get work shift select list error: ', error.message);
          });
        }
      });
    },

    /**
     * 获取“考勤状态”枚举
     */
    getAttendanceStateSelectList (context) {
      return new Promise((resolve, reject) => {
        if (context.state.attendanceStateEnum && context.state.attendanceStateEnum.length) {
          resolve(context.state.attendanceStateEnum);
        } else {
          ajax.post(config.apiConf.serviceApi + 'workshift/listAttendanceStatus')().then(res => {
            if (res.code==200 && res.data) {
              context.commit('setAttendanceStateEnum', res.data);
              resolve(res.data);
            } else {
              reject(new Error(res.message));
            }
          }).catch(error => {
            reject(error);
            console.error('Get attendance state select list error: ', error.message);
          });
        }
      });
    }
  }
}
