import store from '@/store'
import cache from '@/libs/cache'

var CACHE_MENU_LIST_KEY = 'menu_list';
var CACHE_PAGE_URLS_KEY = 'page_urls';
var CACHE_PAGE_BUTTONS_KEY = 'page_button';
var CACHE_USER_INFO_KEY = 'user_info';
var CACHE_CHAIN_DATA_KEY = 'chain_data';


/** menu **/
/**
 * 获取缓存的菜单列表
 * @returns { Array|Null } 缓存值
 */
function getLocalCachedMenuList () {
  return cache.local().get(CACHE_MENU_LIST_KEY);
}

/**
 * 缓存菜单列表
 * @note 缓存原始数据
 * @param { Array|Null } 缓存值
 */
function cacheMenuList (data) {
  cache.local().set(CACHE_MENU_LIST_KEY, data || []);
}



/** page url **/
/**
 * 获取缓存的 page urls
 * @returns { Array|Null } 缓存值
 */
function getCachedPageUrls () {
  let urls = store.state.user.pageUrls;
  if (!urls) {
    urls = getLocalCachedPageUrls();
    _cacheMemoryPageUrls(urls);
  }
  return urls;
}

/**
 * 获取本地缓存的 page urls
 * @returns { Array|Null } 缓存值
 */
function getLocalCachedPageUrls () {
  return cache.local().get(CACHE_PAGE_URLS_KEY);
}

/**
 * 缓存 page urls
 * @note 权限对应的 page urls
 * @param { Array|Null } data 缓存值
 */
function cachePageUrls (data) {
  _cacheMemoryPageUrls(data);
  cache.local().set(CACHE_PAGE_URLS_KEY, data || []);
}

/**
 * 内存缓存 page urls
 * @param { Array|Null } data 缓存值
 */
function _cacheMemoryPageUrls (data) {
  store.commit('setPageUrls', data);
}

/**
 * 清除 page urls 内存缓存
 */
function clearMemoryPageUrls () {
  _cacheMemoryPageUrls(null);
}



/** page button **/
/**
 * 获取缓存的 page buttons
 * @returns { Array } 缓存值
 */
function getLocalCachedPageButtons () {
  return cache.local().get(CACHE_PAGE_BUTTONS_KEY);
}

/**
 * 缓存 page buttons
 * @note 权限对应的 page buttons
 * @param { Array|Null } data 缓存值
 */
function cachePageButtons (data) {
  _cacheMemoryPageButtons(data);
  cache.local().set(CACHE_PAGE_BUTTONS_KEY, data || []);
}

/**
 * 内存缓存 page buttons
 * @param { Array|Null } data 缓存值
 */
function _cacheMemoryPageButtons (data) {
  store.commit('setPageButton', data);
}



/** user info **/
/**
 * 获取缓存的用户信息
 * @returns { Object } 缓存值
 */
function getLocalCachedUserInfo () {
  return cache.local().get(CACHE_USER_INFO_KEY);
}

/**
 * 缓存 user info
 * @param { Object } data 缓存值
 */
function cacheUserInfo (data) {
  _cacheMemoryUserInfo(data);
  cache.local().set(CACHE_USER_INFO_KEY, data);
}

/**
 * 内存缓存 user info
 * @param { Object } data 缓存值
 */
function _cacheMemoryUserInfo (data) {
  store.commit('setUserInfo', data);
}



/** chain data **/
/**
 * 获取缓存的连锁数据
 * @returns { Object|String|Number|Null } 缓存值
 */
function getLocalCachedChainData () {
  return cache.local().get(CACHE_CHAIN_DATA_KEY);
}

/**
 * 缓存 chain data
 * @param { Object|String|Number } data 缓存值
 */
function cacheChainData (data) {
  cache.local().set(CACHE_CHAIN_DATA_KEY, data);
}



/**
 * 清除本地项目缓存
 * @note 根据项目实际情况，选择清除所有或指定缓存
 */
function clearLocalCache () {
  cache.local().clear();
  clearMemoryPageUrls();
}


export default {
  getLocalCachedMenuList,
  cacheMenuList,
  getCachedPageUrls,
  cachePageUrls,
  getLocalCachedPageButtons,
  cachePageButtons,
  getLocalCachedUserInfo,
  cacheUserInfo,
  getLocalCachedChainData,
  cacheChainData,
  clearLocalCache
}
