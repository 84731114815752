
import {Icon as IconifyIcon, addIcon } from '@iconify/vue2'

addIcon('lz-icon-xiala', {
    body: '<path d="M0.0750020997,1.56472249 L2.70000022,4.85881938 C2.77082056,4.94769464 2.8819661,5 3,5 C3.1180339,5 3.22917944,4.94769464 3.29999978,4.85881938 L5.9249979,1.56472249 C6.01022155,1.45777194 6.02393002,1.31468117 5.96040806,1.19510682 C5.89688611,1.07553248 5.76703753,1 5.62499812,1 L0.375001881,1 C0.232962465,1 0.103113894,1.07553248 0.0395919361,1.19510682 C-0.023930022,1.31468117 -0.0102215461,1.45777194 0.0750020997,1.56472249 Z" fill="currentColor" fill-rule="nonzero"></path>',
    width: 6,
    height: 6
})

addIcon('lz-icon-jia', {
    body: '<path d="M6.66666666 5.33045901L12 5.33045901 12 6.66307374 6.66666666 6.66307374 6.66666666 12 5.33333334 12 5.33333334 6.66307374 0 6.66307374 0 5.33045901 5.33333334 5.33045901 5.33333334 0 6.66666666 0z" fill="currentColor" fill-rule="nonzero"></path>',
    width: 12,
    height: 12
})

addIcon('lz-icon-kehuguanli',{
    body:'<g fill-rule="nonzero" fill="none">\
            <path d="M4.23265862,9.95382431 C4.24890867,9.80007387 4.2595337,9.66569849 4.27765875,9.53319811 C4.30890884,9.30257245 4.34640894,9.07382181 4.38015904,8.84382115 C4.39640909,8.72882083 4.40703412,8.6125705 4.42078416,8.49757017 L4.49140935,7.96506866 C4.5051594,7.86194337 4.51890944,7.75819308 4.53015947,7.65444278 C4.54578451,7.50006734 4.55703454,7.3444419 4.57390959,7.18944145 C4.58265961,7.10694122 4.60015966,7.02506599 4.60828469,6.94194075 C4.62578474,6.77194027 4.55578455,6.61818983 4.51015941,6.45943938 C4.49515937,6.40318922 4.47328431,6.34693906 4.46640928,6.2900639 C4.46390927,6.27131384 4.49953437,6.23131373 4.51765943,6.23131373 C4.84891037,6.22756372 5.18016131,6.22881372 5.51141225,6.22881373 C5.55891239,6.22881373 5.57641244,6.24006376 5.55891239,6.29318891 C5.52141228,6.40568924 5.4926622,6.51818955 5.46141211,6.63131487 C5.44891207,6.676315 5.43328703,6.72194012 5.425787,6.76819026 C5.39953693,6.9256907 5.422037,7.08131615 5.45453708,7.23819159 C5.47828715,7.3488169 5.48578717,7.46256722 5.49641221,7.57506755 C5.51141225,7.73944301 5.52016228,7.90381848 5.54016233,8.06756895 C5.57141242,8.30694463 5.60891253,8.54507031 5.64266262,8.78382099 C5.65828767,8.89007128 5.6714127,8.99632159 5.68141274,9.10319689 C5.69766278,9.26444735 5.70953781,9.42632281 5.72516286,9.58632326 C5.73453788,9.68507354 5.74766292,9.78257382 5.76266296,9.8800741 C5.76578797,9.89882416 5.79078804,9.9138242 5.82141313,9.94819929 C5.86766326,9.77069879 \
                5.90453837,9.61757335 5.94703849,9.46632292 C5.98953861,9.31069748 6.03891375,9.15757204 6.08328888,9.00257161 C6.11391396,8.8944463 6.13953904,8.78507098 6.16828912,8.67694568 C6.20141421,8.55882035 6.23641431,8.44194501 6.2682894,8.32319467 C6.29828949,8.21569437 6.32328955,8.10631906 6.35391465,7.99819375 C6.38516474,7.88256843 6.41953984,7.7669431 6.45203993,7.65131777 C6.48079001,7.54506747 6.50954009,7.43631715 6.53954017,7.32881685 C6.57266526,7.20756651 6.60829036,7.08694116 6.64141546,6.96506582 C6.67079055,6.85381551 6.69579062,6.74194019 6.7257907,6.63131487 C6.75704079,6.51568954 6.79329089,6.40068921 6.82579098,6.28631389 C6.85516607,6.18068859 6.88079114,6.0750633 6.90954123,5.96943799 C6.92204126,5.92131286 6.92329127,5.85693767 6.95516635,5.8306876 C6.99266645,5.79943751 7.06704167,5.77943745 7.11016679,5.79443749 C7.31016736,5.86568769 7.50391792,5.95256294 7.70204348,6.03006317 C7.90641905,6.10943839 8.11266964,6.1831886 8.31767022,6.26131382 C8.48017068,6.32318899 8.64267114,6.38631417 8.80454661,6.45006435 C9.0126722,6.53381458 9.21204776,6.63193987 9.39392328,6.76444025 C9.55767375,6.8838156 9.68704912,7.03131601 9.76017432,7.21569153 C9.79329942,7.29694176 9.79142441,7.39069203 9.80142444,7.48069229 C9.81767448,7.64381775 9.82642451,7.80881822 9.84329955,7.97319368 C9.86392461,8.16569423 9.88954968,8.35819478 9.91204975,8.55132032 L9.91454975,8.5775704 C9.9289248,8.77319595 9.94267483,8.96694651 9.95829988,9.16132206 \
                C9.96892491,9.29569744 9.97954994,9.43007283 9.99767499,9.5631982 C10.0258001,9.77819881 9.79392441,10.0019494 9.56392376,10.0000861 C8.30517018,9.98882441 7.04641661,9.99194942 5.78578803,9.99194942 C4.01765801,9.99194942 2.24890299,9.99319942 0.479522956,9.99757443 C0.322647508,9.99757443 0.187022125,9.96382433 0.0988968797,9.83819898 C-0.00610341895,9.68694856 -0.0198534596,9.51257306 0.022021657,9.33569756 C0.0263966691,9.31819751 0.0357716976,9.30132245 0.0357716976,9.28444741 C0.051396742,9.09257187 0.0620217699,8.90132133 0.080771827,8.70944577 C0.0976468707,8.54694531 0.124521947,8.38444485 0.146397008,8.22194439 L0.14952202,8.19569431 C0.163897065,8.00444377 0.161397057,7.80944321 0.197022158,7.62194268 C0.23139726,7.43944216 0.202022175,7.24631661 0.326397525,7.07631614 C0.45139788,6.90881566 0.599523304,6.77194027 0.772648792,6.66193995 C1.03639954,6.49443948 1.33702539,6.40256421 1.62890122,6.2919389 C1.85827687,6.20443865 2.08452752,6.10756338 2.31327817,6.01631313 C2.50577872,5.9394379 2.69952927,5.86381269 2.89452981,5.79318749 C2.98453007,5.7594374 3.0664053,5.82131257 3.08703036,5.91318783 C3.11453045,6.03193817 3.15578056,6.1494385 3.18703065,6.26881384 C3.21640573,6.37631415 3.2414058,6.48568946 3.27078089,6.59381476 C3.30328098,6.7125651 3.33953108,6.82944043 3.37265617,6.94819077 C3.40078125,7.05319107 3.42703133,7.15881637 3.45640642,7.26444167 C3.50203155,7.43069215 3.55015668,7.59631761 3.59578181,7.76381809 \
                C3.62515689,7.86819338 3.65078196,7.97381869 3.67828204,8.07819398 C3.71203214,8.20069433 3.74890724,8.32069467 3.78203234,8.44257002 C3.81140743,8.55069533 3.83578249,8.65944564 3.86578257,8.76757094 C3.89828266,8.88632128 3.93390776,9.00382161 3.96578286,9.12194694 C3.99578295,9.23069725 4.02265802,9.33882256 4.0507831,9.44819787 C4.08953321,9.5981983 4.12703332,9.74944872 4.16953344,9.89944915 C4.17328345,9.91819921 4.20265853,9.92882424 4.23203362,9.95382431 L4.23265862,9.95382431 Z" fill="currentColor"></path>\
            <path d="M2.66390417,2.50567815 C2.69827927,2.3150526 2.73890438,2.07817694 2.78640451,1.84192627 C2.84140467,1.56130047 2.96765503,1.30879976 3.12265546,1.06942407 C3.34526305,0.720400822 3.65618497,0.436431222 4.02390802,0.246296738 C4.1926585,0.158796487 4.37078401,0.0869212813 4.56203455,0.0587962053 C4.70453495,0.0375461398 4.84578536,0.00067103979 4.98766077,0 C5.13578619,-0.00120396397 5.28516161,0.0231711045 5.43141203,0.0525461797 C5.56766241,0.0800462609 5.7045378,0.118796365 5.83266316,0.171296519 C6.18641417,0.315046931 6.49766505,0.546922589 6.73704073,0.845048431 C6.91641625,1.06504905 7.05829164,1.30379973 7.16079194,1.56255047 C7.2201671,1.71130089 7.24954219,1.87255135 7.28266728,2.0294268 C7.31141736,2.1719272 7.34516746,2.31817762 7.34395117,2.46192803 C7.33766744,2.66192859 7.31891738,2.86067916 7.28641729,3.05817972 C7.27079225,3.17443005 7.23704215,3.28818038 7.21016708,3.4025557 C7.15954194,3.62130632 7.06266666,3.8225569 6.95891636,4.02130745 C6.78141586,4.35880841 6.54204018,4.64630923 6.24328933,4.88880992 C6.05026729,5.04799431 5.8291733,5.1696702 5.59141248,5.24756094 C5.37766187,5.31631113 5.16141126,5.36381127 4.93766062,5.34943622 C4.58098147,5.32757964 4.23680476,5.20990316 3.94140779,5.00881026 C3.67578203,4.82880975 3.44390638,4.61318414 3.27140588,4.34505837 C3.14952727,4.15971916 3.0399174,3.96659701 2.94327995,3.76693173 C2.86390473,3.59693125 2.81077957,3.41255573 2.75827943,3.23130521 \
                C2.73077935,3.14067996 2.72702934,3.04380468 2.71452931,2.95005441 C2.69827926,2.81817904 2.68390422,2.68755367 2.66327916,2.50630315 L2.66390417,2.50567815 Z" fill="currentColor" opacity=".60000002"></path>\
        </g>', 
    width: 10,
    height: 10
})

addIcon('lz-icon-bianji',{
    body:'<path d="M2.18579422,9.60000635 C2.21579413,9.60000635 2.24579406,9.59700636 2.27579398,9.59280637 L4.79878729,9.15000754 C4.82891631,9.14459537 4.85660415,9.12991242 4.87798708,9.10800765 L11.2361703,2.74922449 C11.2944588,2.69088988 11.2944588,2.59635965 11.2361703,2.53802504 L8.74377686,0.043231649 C8.71545993,0.0150175673 8.67694627,-0.000560989852 8.63697714,1.5446164e-05 C8.59721519,-0.000398943738 8.55894816,0.0151673066 8.53077742,0.043231649 L2.17199426,6.40201482 C2.15026483,6.42376884 2.13562376,6.45158687 2.12999437,6.4818146 L1.68779554,9.00420793 C1.66219533,9.15152844 1.70277368,9.30264693 1.79872658,9.41732799 C1.89467948,9.53200905 2.03626805,9.59861295 2.18579422,9.59940635 L2.18579422,9.60000635 Z M3.19619155,6.98401327 L8.63697714,1.54502768 L9.73617422,2.64422476 L4.29598863,8.08381036 L2.96219217,8.31900975 L3.19619155,6.98401327 L3.19619155,6.98401327 Z M11.5199695,10.860003 L0.479998738,10.860003 C0.352695159,10.860003 0.230605602,10.9105742 0.140588376,11.0005914 C0.0505711509,11.0906086 0,11.2126982 0,11.3400017 L0,11.8800003 C0,11.9460001 0.053999857,12 0.119999692,12 L11.8799685,12 C11.9117944,12 11.9423168,11.9873572 11.9648211,11.9648529 C11.9873255,11.9423486 11.9999682,11.9118262 11.9999682,11.8800003 L11.9999682,11.3400017 C11.9999682,11.2126982 11.9493971,11.0906086 11.8593799,11.0005914 C11.7693626,10.9105742 11.6472731,10.860003 11.5199695,10.860003 L11.5199695,10.860003 Z" fill="currentColor" fill-rule="nonzero"></path>',
    width: 12,
    height: 12
})

addIcon('lz-icon-qiye',{
    body:'<g fill-rule="nonzero" fill="none">\
            <path d="M7.33333333,0.437641452 L7.33333333,12 L0.611111116,12 C0.244444449,12 0,11.7565819 0,11.3914548 L0,2.14156798 C0,1.83729539 0.183333333,1.59387731 0.488888884,1.53302279 L6.6,0.0116598313 C6.90555555,-0.0491946921 7.21111112,0.133368864 7.33333333,0.437641452 Z M4.88888888,6.52309332 L2.44444445,6.52309332 C2.07777778,6.52309332 1.83333333,6.7665114 1.83333333,7.13163851 C1.83333333,7.4359111 2.07777778,7.67932918 2.38333333,7.74018369 L4.88888888,7.74018369 C5.25555555,7.74018369 5.5,7.49676561 5.5,7.13163851 C5.5,6.76651142 5.25555555,6.52309332 4.88888888,6.52309332 L4.88888888,6.52309332 Z M4.88888888,3.48036739 L2.44444445,3.48036739 C2.07777778,3.48036739 1.83333333,3.72378547 1.83333333,4.08891257 C1.83333333,4.39318516 2.07777778,4.63660324 2.38333333,4.69745776 L4.88888888,4.69745776 C5.25555555,4.69745776 5.5,4.45403968 5.5,4.08891257 C5.5,3.72378546 5.25555555,3.48036739 4.88888888,3.48036739 L4.88888888,3.48036739 Z" fill="currentColor"></path>\
            <path d="M7.94444445,5.91454813 L10.6333333,6.94907496 C10.8777778,7.07078399 11,7.25334755 11,7.49676562 L11,11.3914548 C11,11.7565819 10.7555556,12 10.3888889,12 L7.94444445,12 L7.94444445,5.91454813 L7.94444445,5.91454813 Z" fill="currentColor" opacity=".60000002"></path>\
        </g>',
    width: 11,
    height: 12
})

addIcon('lz-icon-xia',{
    body:'<path fill="currentColor" fill-rule="nonzero" d="M0.65001436 2L3.99977031 5.6025489 7.34998564 2.00049397 8 2.69897253 3.99977031 7 0 2.69897253z"></path>',
    width: 8,
    height: 8
})

addIcon('lz-icon-suosuo',{
    body:'<path d="M10.199135,9.47106961 L12,11.2606142 L11.2411341,12 L9.45064872,10.220853 C8.41633172,11.0835882 7.11239537,11.5550373 5.76645844,11.5529027 C2.58164345,11.5529027 0,8.96678541 0,5.77645134 C0,2.58611727 2.58164345,0 5.76645844,0 C8.95127343,0 11.5329169,2.58611726 11.5329169,5.77645134 C11.534862,7.12661356 11.0627227,8.43445443 10.199135,9.47106961 Z M5.76645844,10.3976124 C7.41465067,10.3977123 8.93768577,9.51694584 9.76181068,8.0871126 C10.5859356,6.65727936 10.5859356,4.89562331 9.76181068,3.46579007 C8.93768577,2.03595683 7.41465067,1.15519036 5.76645844,1.15529027 C3.21878582,1.15544471 1.15357086,3.22436376 1.15357086,5.77645133 C1.15357086,8.32853891 3.21878582,10.397458 5.76645844,10.3976124 Z" fill="currentColor" fill-rule="nonzero"></path>',
    with: 12,
    height: 12
})

IconifyIcon.install = function(Vue) {
    Vue.component('IconifyIcon', IconifyIcon);
};
/**
 * icon使用示例
 * <IconifyIcon icon="lz-icon-xiala" color="red"/>
 * <IconifyIcon icon="lz-icon-jia"/>
 * <IconifyIcon icon="lz-icon-kehuguanli"/>
 * <IconifyIcon icon="lz-icon-bianji"/>
 * <IconifyIcon icon="lz-icon-qiye"/>
 * <IconifyIcon icon="lz-icon-xia"/>
 * <IconifyIcon icon="lz-icon-suosuo"/>
*/

export default IconifyIcon;