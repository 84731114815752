import { Notification } from 'element-ui'

let notice = {};

['success', 'info', 'warning', 'error'].forEach(type => {
  notice[type] = options => {
    let tmpOptions = {};
    if (typeof options == 'string') {
      tmpOptions.message = options;
    } else {
      tmpOptions = options;
    }
    if (!tmpOptions.title) {
      tmpOptions.title = _defaultTitle(type);
    }
    Notification[type](tmpOptions);
  }
});

notice.open = function (options) {
  let type = options.type || 'success';
  if (!options.title) {
    options.title = _defaultTitle(type);
  }
  Notification[type](options);
}

notice.close = function () {
  Notification.closeAll();
}


/**
 * 获取默认 title
 * @param { String } type 类型
 * @returns { String } 默认 title
 */
function _defaultTitle (type) {
  let title = '';
  switch (type) {
    case 'success':
      title = '成功';
      break;
    case 'info':
      title = '提示';
      break;
    case 'warning':
      title = '警告';
      break;
    case 'error':
      title = '失败';
      break
  }
  return title;
}


/**
 * 封装自定义 Notification 组件
 */
export default notice;
