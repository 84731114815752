
/**
 * 查找路由列表中 name 为指定值的对象
 * @param { String } name 菜单别名
 * @param { Array } routes 本地路由列表
 */
function _getNameRoute (name, routes) {
  const len = routes.length;
  let i = -1;
  let nameRoute = {};
  while (++i < len) {
    let item = routes[i];
    if (item.name === name) {
      nameRoute = item;
      return nameRoute;
    } else if (item.children && item.children.length) {
      let res = _getNameRoute(name, item.children);
      if (res.name) return res;
    }
  }
  return nameRoute;
}

/**
 * 递归查找有效的叶子节点菜单项
 * @param { Object } menuItem 菜单项数据
 * @param { Array } routes 本地路由列表
 */
function _checkMenuItem (menuItem, routes) {
  let tmpItem;
  let route = _getNameRoute(menuItem.name, routes);
  if (Object.keys(route).length !== 0) {
    tmpItem = menuItem;

    let childMenus = [],
        key = '';
    if (menuItem.children) {
      childMenus = menuItem.children || [];
      key = 'children';
    } else if (menuItem.childMenus) {
      childMenus = menuItem.childMenus || [];
      key = 'childMenus';
    }
    menuItem[key] = [];
    childMenus.forEach(childItem => {
      const data = _checkMenuItem(childItem, routes);
      if (data) {
        menuItem[key].push(data);
      }
    });
  }
  return tmpItem;
}

/**
 * 获取有效菜单项
 * @note office 配置菜单需存在本地路由配置才能有效
 * @param { Array } menuData 菜单项
 * @param { Array } routes 本地路由列表
 * @returns { Array } 菜单列表
 */
function checkMenuData (menuData, routes) {
  let list = [];
  if (Array.isArray(menuData) && menuData.length>0) {
    menuData.forEach(item => {
      // HRMS 系统暂时只检查第一层
      // const tmpItem = _checkMenuItem(item, routes);
      const tmpItem = _getNameRoute(item.name, routes);
      if (tmpItem && Object.keys(tmpItem).length!==0) {
        list.push(item);
      }
    });
  }
  return list;
}


/**
 * 获取第一个叶子节点菜单项
 * @param { Array } menuData 菜单项
 * @param { Array } routes 本地路由列表
 * @returns { Object|Null } 菜单项
 */
function checkLeafMenuData (menuData, routes) {
  if (Array.isArray(menuData) && menuData.length) {
    let leafItem;
    for (let i = 0; i < menuData.length; i++) {
      leafItem = _checkLeafMenuItem(menuData[i], routes);
      if (leafItem) {
        break;
      }
    }
    return leafItem;
  } else if (Object.prototype.toString.call(menuData) === '[object Object]') {
    return _checkLeafMenuItem(menuData, routes);
  }
  return null;
}

/**
 * 递归查找第一个有效的叶子节点菜单项
 * @param { Object } menuItem 菜单项数据
 * @param { Array } routes 本地路由列表
 */
function _checkLeafMenuItem (menuItem, routes) {
  let childMenus = [];
  if (menuItem.children) {
    childMenus = menuItem.children;
  } else if (menuItem.childMenus) {
    childMenus = menuItem.childMenus;
  }
  let menu;
  for (let i = 0; i < childMenus.length; i++) {
    let childMenu = childMenus[i];
    menu = _checkLeafMenuItem(childMenu, routes);
    if (menu) {
      break;
    }
  }
  if (menu) {
    return menu;
  }
  let route = _getNameRoute(menuItem.name, routes);
  if (Object.keys(route).length) {
    return menuItem;
  }
  return null;
}


/**
 * 菜单数据与本地路由配置处理
 */
export default {
  checkMenuData,
  checkLeafMenuData
}
