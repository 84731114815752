/**
 * 从 url 中获取查询参数值
 * @param { String } url 被处理 url
 * @param { String } name 参数名
 * @returns { String } 参数值
 */
export const getQueryValue = (url, name) => {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  const r = url.indexOf('?') > -1 ? url.split('?')[1].match(reg) : url.match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}
