<template>
  <div class="header-bar display__flex align_items__center">
    <div class="title-wrapper ml-20">{{ title }}</div>
    <div class="custom-content flex_grow__1">
      <div class="custom-content-left">
        <slot name="menu"></slot>
      </div>
      <div class="custom-content-con f-center">
        <slot name="user"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderBar',
  props: {
    title: ''
  }
}
</script>

<style lang="scss" scoped src="./header-bar.scss"></style>
