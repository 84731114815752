var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header-bar display__flex align_items__center" },
    [
      _c("div", { staticClass: "title-wrapper ml-20" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _c("div", { staticClass: "custom-content flex_grow__1" }, [
        _c("div", { staticClass: "custom-content-left" }, [_vm._t("menu")], 2),
        _c(
          "div",
          { staticClass: "custom-content-con f-center" },
          [_vm._t("user")],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }