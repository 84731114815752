var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lz-pagination" },
    [
      _vm.showTotal
        ? _c("div", { staticClass: "lz-pagination__desc" }, [
            _vm._v(
              "[共" +
                _vm._s(_vm.total) +
                "条 每页" +
                _vm._s(_vm.pageSize) +
                "条 当前第" +
                _vm._s(_vm.currentPage) +
                "/" +
                _vm._s(_vm.pageCount) +
                "页]"
            )
          ])
        : _vm._e(),
      _c("el-pagination", {
        staticClass: "lz-pagination__content",
        attrs: {
          total: _vm.total,
          "page-size": _vm.pageSize,
          "current-page": _vm.currentPage,
          "prev-text": "上一页",
          "next-text": "下一页",
          "page-sizes": [10, 20, 50],
          layout: "prev, pager, next, jumper"
        },
        on: {
          "size-change": _vm.handlePaginationSizeChange,
          "current-change": _vm.handlePaginationOnChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }