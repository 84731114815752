var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "main" },
    [
      _c(
        "el-aside",
        { staticClass: "left-sider", attrs: { width: "180px" } },
        [
          _c(
            "div",
            {
              staticClass:
                "system-con display__flex justify_content__center align_items__center"
            },
            [
              _vm.templateMode.logo
                ? _c("img", {
                    staticClass: "system-logo-img",
                    attrs: { alt: "", src: _vm.templateMode.logo }
                  })
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(_vm.templateMode.name))])
            ]
          ),
          _c("lz-side-menu", {
            attrs: { "menu-list": _vm.menuList },
            on: { "on-select": _vm.turnToPage }
          })
        ],
        1
      ),
      _c(
        "el-container",
        { staticClass: "layout-content" },
        [
          _c(
            "el-header",
            { staticClass: "header-con" },
            [
              _c("header-bar", {
                attrs: { title: _vm.userOrgName },
                scopedSlots: _vm._u([
                  {
                    key: "user",
                    fn: function() {
                      return [
                        _c("user", { attrs: { userInfo: _vm.userInfo } }),
                        _c("SwitchLang")
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-main",
            { staticClass: "main-content-con" },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }