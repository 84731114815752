var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lz-page-panel" },
    [
      _vm.showHeader
        ? [
            _vm.customHeader
              ? _vm._t("header")
              : _c("div", { staticClass: "lz-panel__header" }, [
                  _c("label", { staticClass: "lz-panel__title" }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                ])
          ]
        : _vm._e(),
      _c("div", { staticClass: "lz-panel__body" }, [_vm._t("default")], 2)
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }