import axios from 'axios'
import systemHelper from '@/libs/system'
import chainHelper from '@/view/common/chain'
import { getSession} from '@/libs/stroage'

// Cancel token that can be used to cancel the request
const CancelToken = axios.CancelToken;

class HttpRequest {
  constructor() {
    // 记录进行中的 request 信息，用于取消请求
    this.processingRequests = [];
  }

  getInsideConfig() {
    const config = {
      headers: {},
      withCredentials: true
    }
    return config;
  }

  /**
   * 获取 request 指纹信息
   * @note 目前只处理 url + method
   * @returns { String } 指纹信息
   */
  getRequestFingerprint(options) {
    return options.method + '__' + options.url;
  }

  /**
   * 添加进行中的 request 指纹信息
   * @param { Object } config request 配置信息
   * @param { Function } cancelFunc axios cancel function
   */
  addProcessingRequest(config, cancelFunc) {
    let fingerprint = this.getRequestFingerprint(config);
    this.processingRequests.push({
      fingerprint: fingerprint,
      cancelFunc: cancelFunc
    });
  }

  /**
   * 移除 request 指纹信息，同时取消 request
   * @param { Object } config request 配置信息
   */
  removeProcessingRequest(config) {
    let fingerprint = this.getRequestFingerprint(config);
    let index = this.processingRequests.findIndex(item => item.fingerprint === fingerprint);
    if (index !== -1) {
      // 取消 request 请求
      this.processingRequests[index].cancelFunc('Duplicate request canceled');
      this.processingRequests.splice(index, 1);
    }
  }

  interceptors(instance, url, extraConfig) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      if (extraConfig && extraConfig.autoCancel) {
        // 取消进行中的相同请求，记录并开始当前请求
        let options = { method: config.method, url: config.url };
        this.removeProcessingRequest(options);
        /**
         * 处理多语言的问题
        */
        config.cancelToken = new CancelToken(cancelFunc => {
          this.addProcessingRequest(options, cancelFunc);
        });
      }
      //storage和cookie 有区别；导致时间差的问题
      Object.assign(config.headers, { 
        'x-access-titc-s-token': getSession('s-token') || '',
         multilingual : window.__tci18n_locale__ || 'en-us'
       });
      let chainData = chainHelper.getChainData();
      if (chainData) {
        Object.assign(config.headers, chainData);
      }

      return config;
    }, error => {
      return Promise.reject(error);
    })

    // 响应拦截
    instance.interceptors.response.use(res => {
      if (extraConfig && extraConfig.autoCancel) {
        // 请求完成，移除 request 记录
        let options = { method: res.config.method, url: res.config.url};
        this.removeProcessingRequest(options);
      }

      return res;
    }, error => {
      if (error.response && error.response.status === 401) {
        systemHelper.loginByOAuth('N');
      }
      console.error({ error });
      return Promise.reject(error);
    })
  }

  request(options, extraConfig) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options.url, extraConfig);
    return instance(options);
  }
}

export default HttpRequest
