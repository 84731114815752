var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "vertical-center",
      attrs: {
        visible: _vm.visible,
        width: _vm.width,
        top: "0",
        "append-to-body": "",
        "close-on-click-modal": _vm.maskClosable,
        "close-on-press-escape": _vm.maskClosable,
        "custom-class": "lz-dialog lz-dialog-" + _vm.name
      },
      on: { close: _vm.handleDialogClose }
    },
    [
      !_vm.headerHide
        ? _c(
            "div",
            {
              staticClass: "lz-dialog__header",
              attrs: { slot: "title" },
              slot: "title"
            },
            [_c("span", [_vm._v(_vm._s(_vm.title))])]
          )
        : _vm._e(),
      [
        _vm.customBody
          ? _vm._t("body")
          : _c("div", { staticClass: "lz-dialog__body" }, [
              _c("div", { staticClass: "lz-dialog__title" }, [
                _c("i", {
                  staticClass:
                    "lz-dialog__title__icon element-icons el-icon-warning"
                }),
                _c(
                  "span",
                  { class: ["lz-dialog__body__title", { bold: _vm.content }] },
                  [_vm._v(_vm._s(_vm.title))]
                )
              ]),
              _vm.content
                ? _c("div", { staticClass: "lz-dialog__body__content" }, [
                    _vm._v(_vm._s(_vm.content))
                  ])
                : _vm._e()
            ])
      ],
      !_vm.footerHide
        ? _c(
            "template",
            { slot: "footer" },
            [
              _vm.customFooter
                ? _vm._t("footer")
                : _c(
                    "div",
                    { staticClass: "lz-dialog__footer" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "lz-dialog__footer__button confirm",
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleDialogConfirm()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.confirmButtonText))]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "lz-dialog__footer__button cancel",
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleDialogCancel()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.cancelButtonText))]
                      )
                    ],
                    1
                  )
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }