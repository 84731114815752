<template>
  <el-dialog
    :visible="visible"
    :width="width"
    top="0"
    append-to-body
    :close-on-click-modal="maskClosable"
    :close-on-press-escape="maskClosable"
    class="vertical-center"
    :custom-class="'lz-modal lz-modal-'+name"
    @close="handleModalClose">
    <template slot="title">
      <slot name="header" v-if="customHeader"></slot>
      <div v-else class="lz-modal__header">
        <span>{{ title }}</span>
      </div>
    </template>

    <slot name="body"></slot>

    <template slot="footer" v-if="!footerHide">
      <slot name="footer" v-if="customFooter"></slot>
      <div v-else class="lz-modal__footer">
        <el-button type="primary" size="small" class="lz-modal__footer__button confirm" @click="handleModalConfirm()">{{ confirmButtonText }}</el-button>
        <el-button size="small" class="lz-modal__footer__button cancel" @click="handleModalCancel()">{{ cancelButtonText }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
/**
 * 封装自定义 Modal 组件
 */
export default {
  name: 'LzModal',
  model: {
    // 自定义用于 v-model 的 prop 和事件
    prop: 'visible',
    event: 'change'
  },
  props: {
    // modal 是否显示
    visible: {
      type: Boolean,
      default: false
    },

    // modal 标识
    name: {
      type: String,
      default: ''
    },

    // modal 标题
    title: {
      type: String,
      default: ''
    },

    // 确定、取消按钮文案
    confirmButtonText: {
      type: String,
      default: '确定'
    },

    cancelButtonText: {
      type: String,
      default: '取消'
    },

    // modal 宽度
    width: {
      type: String,
      default: '50%',
    },

    // 是否允许点击遮罩层关闭
    maskClosable: {
      type: Boolean,
      default: true
    },

    // 是否显示 header
    headerHide: {
      type: Boolean,
      default: true
    },

    // 是否显示 footer
    footerHide: {
      type: Boolean,
      default: false
    },
    
    // 是否显示 loading 视图
    loadingEnable: Boolean,
    loading: Boolean,
  },
  data () {
    return {
      // 是否自定义 header
      customHeader: false,
      // 是否自定义 footer
      customFooter: false,

      // loading 对象
      loadingIns: null
    }
  },
  watch: {
    loading (newVal) {
      if (this.loadingEnable) {
        this.setLoading(newVal);
      }
    },
  },
  mounted () {
    this.customHeader = this.$slots.header !== undefined;
    this.customFooter = this.$slots.footer !== undefined;
  },
  methods: {
    /**
     * 设置 loading
     * @param { Boolean } loading 是否是 loading 状态
     */
    setLoading (loading) {
      if (loading) {
        this.loadingIns = this.$loading({
          lock: true,
          target: this.name ? `.lz-modal-${this.name}` : '.lz-modal'
        });
      } else if (!loading && this.loadingIns) {
        this.loadingIns.close();
        this.loadingIns = null;
      }
    },

    closeModal () {
      // 修改 v-model prop 值
      this.$emit('change', false);
    },

    // 关闭事件
    handleModalClose () {
      this.$emit('close');
      this.closeModal();
    },

    /**
     * 确定按钮点击事件
     */
    handleModalConfirm () {
      this.$emit('confirm');
    },

    /**
     * 取消按钮点击事件
     */
    handleModalCancel () {
      this.$emit('cancel');
      this.closeModal();
    }
  }
}
</script>

<style lang="scss">
  @import "@/style/const";

  .v-modal {
    background-color: #000512 !important;
  }

  .el-dialog__wrapper.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lz-modal {
    $_border-color: $border-color;
    $_dividing-line-color: $dividing-line-color;
    $_theme-color: $theme-color;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__headerbtn {
      font-size: 20px;
      top: 10px;
      right: 12px;
    }

    .el-dialog__body {
      padding: 0;
    }

    .el-dialog__footer {
      padding: 0;
      border-top: 1px solid $_dividing-line-color;
    }

    .lz-modal__header {
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      padding: 18px 16px;
      border-bottom: 1px solid $_dividing-line-color;
    }

    .lz-modal__footer {
      height: 52px;
      padding: 10px 16px;
      text-align: right;
      
      &.lz-modal__footer__line {
        border-top: 1px solid $_dividing-line-color;
      }

      .lz-modal__footer__button {
        min-width: 65px;
        padding: 8px 15px;
        font-size: 14px;
        border-radius: 2px;
      }

      .lz-modal__footer__button.confirm {
        color: #FFF;
        background-color: $_theme-color;
        border-color: $_theme-color;
      }

      .lz-modal__footer__button.cancel {
        border: 1px solid $_border-color;
      }
    }

    .lz-modal__footer button + button {
      margin-left: 8px;
    }
  }
</style>
