import { getQueryValue } from '@/libs/util'
import cacheHelper from '@/view/common/cache'

let LOCATION_SEARCH_CHAIN_KEY = 'blocCode';
// chain code name key
let chainCodeName = 'x-access-bloc-code';

/**
 * 解析连锁数据
 * @note
 * - 取到值后，重置 url，防止页面刷新重复执行逻辑
 * - 如果连锁选择变更，则清空本地缓存，重新获取数据
 */
function parseChainDataInURL () {
  let search = window.location.search;
  let data = getQueryValue(search, LOCATION_SEARCH_CHAIN_KEY);
  if (data) {
    let resetURL = _resetLocationURL(window.location.href, data);
    let chainData = cacheHelper.getLocalCachedChainData();
    if (chainData && chainData!=data) {
      cacheHelper.clearLocalCache();
      cacheHelper.cacheChainData(data);
    } else {
      cacheHelper.cacheChainData(data);
    }
    window.location.href = resetURL;
  }
}

/**
 * 重置 url
 * @param { String } url url
 * @returns { String } 重置后的 url
 */
function _resetLocationURL (url, value) {
  let matchStr = LOCATION_SEARCH_CHAIN_KEY + '=' + value;
  return url.replace(matchStr, '');
}


/**
 * 获取“连锁”数据
 * @returns { Object|Null } 连锁数据
 */
function getChainData () {
  let data = cacheHelper.getLocalCachedChainData();
  return data ? { [chainCodeName]: data } : null;
}


/**
 * “连锁”处理
 */
export default {
  parseChainDataInURL,
  getChainData
}
