var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        visible: _vm.visible,
        size: _vm.width,
        "append-to-body": "",
        "wrapper-closable": _vm.maskClosable,
        "close-on-press-escape": _vm.maskClosable,
        "before-close": _vm.beforeClose,
        "custom-class": "lz-drawer lz-drawer-" + _vm.name
      },
      on: { close: _vm.handleDrawerClose }
    },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.customHeader
            ? _vm._t("header")
            : _c("div", { staticClass: "lz-drawer__header" }, [
                _c("span", [_vm._v(_vm._s(_vm.title))])
              ])
        ],
        2
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }